"use client";

import { TokenExpiryTime, backendUri, frontendUri } from "@/config/config";
import CookieHelper from "@/utils/CookieHelper";
import ServerRequest from "@/utils/ServerRequest";
import Cookies from "js-cookie";
import firebase from "firebase/app"; // Import the entire firebase app namespace
import "firebase/auth"; // Import firebase authentication
import "firebase/database"; // Import firebase database (if needed)
import { firebaseConfig } from "@/config/firebase";
import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useTheme } from "next-themes";
import 'firebaseui/dist/firebaseui.css';

function LogIn() {
  const { postData } = ServerRequest();
  const router = useRouter();

  const setAuthToken = (authResult: any) => {
    authResult.user
      .getIdToken()
      .then(async (tokenResult: any) => {
        let ref = Cookies.get("ref") || "";
        try {
          const uri = `${backendUri}/api/v1/auth/firebaselogin`;
          const body = ref ? JSON.stringify({ ref }) : null;
          const headers = { Authorization: `Bearer ${tokenResult}` };

          const response = await postData(
            uri,
            body,
            { formData: false, authorization: false },
            headers
          );

          if (response.status === 401) {
            router.push("/login");
          } else if (response.status === 200) {
            const result = await response.json();
            const CookieExpiryTime = new CookieHelper().getExpiryTimeInMins(
              TokenExpiryTime
            );
            Cookies.set("cat", result.data.token, {
              expires: CookieExpiryTime,
            });

            const jobId = Cookies.get("jobId");
            if (jobId) {
              Cookies.remove("jobId");
              router.push(`/detail/${jobId}`);
            } else {
              router.push("/");
              window.location.pathname = "/";
            }
          } else {
            alert("Something went wrong.");
            console.error("Error:", await response.json());
          }
        } catch (e) {
          console.error("Catch Error:", e);
        } finally {
          router.push("/");
          window.location.pathname = "/";
        }
      })
      .catch((error: Error) => {
        console.error("Firebase Error:", error);
      });
  };

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult: any) {
        setAuthToken(authResult);
        return true;
      },
    },
    recaptchaParameters: {
      type: "image",
      size: "invisible",
      badge: "bottomleft",
    },
    signInFlow: "popup",
    signInOptions: [
      {
        defaultCountry: "IN",
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      },
    ],
    tosUrl: `${frontendUri}/tnc`,
    privacyPolicyUrl: `${frontendUri}/privacy`,
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Initialize Firebase only if it hasn't been initialized already
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
      }

      import("firebaseui").then((firebaseui) => {
        const ui =
          firebaseui.auth.AuthUI.getInstance() ||
          new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);
      });
    }
  }, []);

  const { theme } = useTheme();
  const [imageFlag, setImageFlag] = useState(true);

  useEffect(() => {
    setImageFlag(theme !== "dark");
  }, [theme]);

  return (
    <section className="flex flex-row items-center justify-center gap-4">
      <div className="videotag">
        {imageFlag ? (
          <img src="/otherpages.jpg" alt="Light Theme Background" />
        ) : (
          <img src="/backgroundDark.jpg" alt="Dark Theme Background" />
        )}
      </div>
      <div id="firebaseui-auth-container" className="mb-4"></div>
    </section>
  );
}

export default LogIn;
