import React, { Component } from 'react'

export default class CookieHelper {
    constructor(){

    }
    getExpiryTimeInMins(mins:any){
        return new Date(new Date().getTime() + mins * 60 * 1000);
    }
    // getReferenceExpiry(:any){
    //     return new Date(new Date().getTime() + mins * 60 * 1000);
    // }
    
}
